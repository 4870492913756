<template>
  <v-dialog :value="dialog" width="550px" class="d-flex" persistent>
    <v-card class="dialog-content px-4 py-2">
      <v-card-title class="title mb-1 pa-4">Escolha uma das opções abaixo</v-card-title>
      <v-card-actions class="d-flex justify-end align-self-end">
        <v-row>
          <v-card-text class="pt-0">
            <v-container fluid class="pa-0" grid-list-xl>
              <v-layout row wrap>
                <v-flex v-for="(type, index) in salesTypes" :key="`${index}-${type.text}`" xs6 md6>
                  <type-card :text="type.text" :description="type.description" :sales-type="type.salesType" :selected.sync="selectedType" />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-row>
      </v-card-actions>
      <p v-if="error" class="ma-0 pl-3 error-message">Selecione uma modalidade</p>

      <mf-action-buttons
        class="mr-1"
        :primary-button="{
          text: 'Confirmar',
          action: validateIfHasTypeSelected,
          isVisible: true,
          isDisabled: !selectedType
        }"
        :extra-button="{
          text: 'Cancelar',
          action: closeDialog,
          isVisible: true
        }"
      />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SalesTypeDialog',
  components: {
    TypeCard: () => import('../atoms/TypeCard')
  },
  props: {
    hasNewSale: {
      type: Boolean,
      required: true,
      default: () => false
    }
  },
  data: () => ({
    dialog: true,
    error: false,
    selectedType: ''
  }),
  computed: {
    salesTypes() {
      if (!this.hasNewSale) {
        return [
          {
            text: 'Nova venda',
            salesType: 'new_sale'
          }
        ]
      }
      return [
        {
          text: 'Cross Sell',
          salesType: 'cross_sell'
        },
        {
          text: 'Upsell',
          salesType: 'upsell'
        },
        {
          text: 'Downsell',
          salesType: 'downsell'
        },
        {
          text: 'Abono temporário',
          salesType: 'temporary_churn'
        },
        {
          text: 'Renegociação de MRR',
          salesType: 'discount'
        },
        {
          text: 'Retorno do Abono Temporário',
          salesType: 'return_temporary_churn'
        }
      ]
    }
  },
  watch: {
    selectedType() {
      this.error = false
    }
  },
  methods: {
    closeDialog() {
      this.error = false
      this.$emit('input', false)
    },
    validateIfHasTypeSelected() {
      if (this.selectedType) {
        this.closeDialog()
        this.$emit('select-action', { sales: this.selectedType })
      } else this.error = true
    }
  }
}
</script>

<style lang="scss">
.dialog-content {
  .block-title {
    opacity: 0.8;
    font-size: 18px;
    font-weight: bold;
    color: #000000;
  }

  .error-message {
    color: #fc5258;
  }
}
</style>
